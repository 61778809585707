.mat-mdc-table.flat {
	box-shadow: none;

	th {
	  color: black;
	}
  }

  .mdc-data-table__row:not(.mdc-data-table__row--selected).hover-row:hover,
  .hover-row {
    transition-property: background-color, font-size;
    transition-duration: 0.6s;
  }

  .mdc-data-table__row:not(.mdc-data-table__row--selected).hover-row:hover,
  .hover-row:hover {
    transition-duration: 0.2s;
    background: rgba(0, 0, 0, 0.04);
  }

  .mdc-data-table__row:not(.mdc-data-table__row--selected).hover-row:not(:hover),
  .hover-row:not(:hover) {
	  transition-timing-function: ease-in-out;
  }

  .hover-cell-input:hover:not(:has(input:focus)) {
	  background-color: rgba(0, 0, 0, 0.04);
  }

  .hover-cell-input:has(input:focus) {
	  background-color: rgba(0, 108, 226, 0.08);
  }

  mat-expansion-panel.cell-bottom-border,
  .cell-bottom-border {
	  border-bottom: 1px solid #91919170;
  }

  .cell-right-border {
	  border-right: 1px solid #91919170;
  }

  .cell-left-border {
	  border-left: 1px solid #91919170;
  }

  .cell-top-border {
	  border-top: 1px solid #91919170;
  }

@import '_env-variables';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'AkkuratLLWeb';
  font-feature-settings: 'dlig'on, 'ss01'on, 'ss02'on, 'ss03'on, 'ss05'on;
}

.sticky-page-header-container {
  @extend .page-header-container;
  top: 0px;
  left: 0px;
  position: sticky;
  margin-top: -8px;
}

.page-header-container {
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.page-header-margin {
  margin-bottom: 24px;
}

.page-back-button-container {
  top: 0px;
  left: 0px;
  position: sticky;
  z-index: 300;
  width: 50px;
}

.retry-load-container {
  width: 100%;
  height: 210px;
  margin: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.empty-placeholder {
  width: 100%;
  font-size: 40px;
  color: #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-title {
  display: block;
  color: #464646;
  font-weight: 350;
  font-size: 20pt;
  letter-spacing: 0.5px;
  border-bottom: solid;
  border-bottom-width: 0.5px;
  border-color: #91919170;
  margin-left: -4px;
}

.card-title {
  color: #1d242e;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 5px;
  padding-bottom: 15px;
  position: relative;
}

.card-title::after {
  background-color: $primary-color;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 46px;
}

.card-subtitle {
  font-size: 12px;
  color: $light-text-color;
}

.mat-grid-tile.text-left .mat-figure {
  justify-content: flex-start !important;
  margin-left: 2px;
}

.mat-grid-tile.text-top .mat-figure {
  display: block;
}

.mat-grid-tile .mat-figure:hover>.remove-button {
  /*TODO: This could be done better */
  visibility: visible;
}

.mat-grid-tile.text-right .mat-figure {
  justify-content: flex-end !important;
  margin-right: 2px;
}

.mat-grid-tile.pos-nudge {
  margin-right: 15px;
}

.filter-remove-button {
  width: 24px !important;
}

.filter-remove-button mat-icon {
  font-size: 18px;
}

/* read only checkbox - no hover effect */
.mat-checkbox.read-only-checkbox:hover .mdc-checkbox__ripple {
  background-color: white;
}

.hidden-dialog {
  display: none;
}

.allow-cr {
  white-space: pre;
}

.custom-dialog-container .mat-mdc-dialog-container {
  overflow: hidden;
}

.sub-header>.mat-content {
  overflow: visible;
  justify-content: space-between;
}

.mat-step-label-active {
  font-weight: 500;
}

.mat-step-label .editable-step {
  font-weight: 500;
  color: $dark-primary-text;
}

.vertical-step-header-padding {
  mat-step-header {
    padding: 20px 24px;
  }
}

.mat-vertical-content-container {
  min-height: 8px;
}

.mat-vertical-content {
  padding-top: 16px !important;

  mat-form-field {
    padding-bottom: 8px;
  }

  button.footer-button {
    margin-top: 8px;
  }
}


.large-dialog-theme {
  margin-left: 24px;
  margin-right: 24px;
}

.dialog-theme,
.large-dialog-theme {
  padding: 0 24px;

  .dialog-stepper {
    margin-left: -24px;
  }

  .mat-step-header {
    font-weight: 700 !important;
    font-size: 14px !important;

    .cdk-program-focused {
      background: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .review-container {
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 64px;

    .review-item {
      border: solid rgba(0, 0, 0, 0.16) 1px;
      border-radius: 4px;
      padding: 24px;

      .step-review-header {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
        font-size: 12px;
        letter-spacing: 4px;
        display: block;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      .step-review-row {
        margin-bottom: 7px;
      }

      .step-review-item {
        font-size: 14px;
        font-weight: 700;
      }

      .step-review-value {
        font-size: 14px;
        font-weight: 400;
      }

      .mat-divider.review-divider {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }

    .review-button-container {
      margin-top: 24px;
    }
  }
}

.dialog-directions-panel {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(240, 248, 255, 1)
  ;
  ;

  .dialog-directions-column {
    display: flex;
    flex-direction: column;
    position: relative;

    .dialog-direction-item {
      font-size: 11pt;
    }

    .dialog-direction-item:not(:first-child) {
      margin-top: 10px;
    }

    .dialog-directions-panel-close {
      position: absolute;
      right: 10px;
      bottom: 20px;
      color: $primary-color;
      cursor: pointer;
    }
  }
}

.dialog-directions-panel-open {
  color: rgba(0, 0, 0, 0.64);
  margin-right: 20px;
}

.side-dialog,
.side-dialog-large {
  height: 64px;

  .dialog-header {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;

    mat-divider {
      width: calc(100% + 48px);
      margin: 0px -24px;
    }
  }

  .dialog-header-row {
    height: 40px;
    margin: 12px 0px 11px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    div:first-child {
      button:nth-child(1) {
        margin-left: -8px;
      }
    }

    div {
      display: flex;
      align-content: center;
      flex-direction: row;
    }

    .dialog-title {
      line-height: 40px;
      font-weight: 700;
    }

    .redesign-dialog-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $dark-primary-text
    }
  }

  .dialog-directions-panel {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dialog-directions-column {
      display: flex;
      flex-direction: column;
      position: relative;

      .dialog-direction-item {
        font-size: 11pt;
      }

      .dialog-direction-item:not(:first-child) {
        margin-top: 10px;
      }

      .dialog-directions-panel-close {
        position: absolute;
        right: 10px;
        bottom: 20px;
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  .dialog-directions-panel-open {
    color: rgba(0, 0, 0, 0.64);
    margin-right: 20px;
  }
}

.side-dialog-large {
  margin-left: 24px;
  margin-right: 24px;

  .dialog-header mat-divider {
    width: calc(100% + 96px);
    margin: 0px -48px;
  }
}


.mat-elevation-z4 {
  margin: 5px;
}

.trovata-page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

trovata-page-header {
  margin-bottom: 10px;
}

button.back-button {
  position: absolute;
  top: 25px;
  left: 12px;
}

button.page-back-button {
  position: absolute;
  top: 0px;
  left: -60px;
}

.wide button.page-back-button {
  left: 0px;
  top: 12px;
}

.sticky-page-header {
  padding-bottom: 19px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 24px;
  line-height: 24px;
  margin-top: -8px;
  top: 0px;
  left: 0px;
  position: sticky;
  background-color: white;
  z-index: 200;
  width: 100%;

  .page-title {
    font-weight: 500;
    font-size: 16px;
  }

  .sticky-button-cntrls {
    margin-top: -6px;
  }

  .sticky-header-button {
    margin: -8px 0px;
  }
}

.sticky-page-bottom {
  padding-bottom: 20px;
  padding-top: 19px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  height: 24px;
  line-height: 24px;
  bottom: 0px;
  position: sticky;
  background-color: white;
  z-index: 200;
  width: 100%;
}

.app-search-bar-autocomplete.mat-autocomplete-panel {
  max-height: 336px;
}

.skeleton-loader {
  overflow: hidden !important;
  position: relative !important;
  border: unset !important;
  color: #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  pointer-events: none !important;
  cursor: unset !important;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 1s infinite;
    content: '';
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
    }
  }
}

.skeleton-loader>* {
  visibility: hidden !important;
}

.anomaly-chart .highcharts-tooltip>span {
  background: rgba(0, 0, 0, 0.56);
  border-radius: 4px;
  padding: 4px 8px;

  .mat-caption {
    color: white;
  }
}

.tootltip-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.account-distinction {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  background-color: #F0F0F0;
  border-radius: 2px;
}

.account-distinction-icon {
  width: 15px;
}

.mdc-evolution-chip-set__chips{
  align-items: center;
}

.page-tab-group{
  .mdc-tab__content{
    pointer-events: all!important;
  }
}

.side-dialog-container-1040w{
  width: 1040px !important;
}

.side-dialog-container-550w{
  width: 550px !important;
}
/***************************************************************************************************
********************************************* Margins **********************************************
***************************************************************************************************/

.m-0 {
    margin: 0 !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-45 {
    margin: 45px !important;
}

.m-50 {
    margin: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mt--32 {
  margin-top: -32px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-24 {
    margin-right: 24px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-12 {
	margin-left: 12px;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-16 {
    margin-left: 16px;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

/***************************************************************************************************
********************************************* Padding **********************************************
***************************************************************************************************/

.p-5 {
    padding: 5px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-16 {
    padding: 20px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-24 {
    padding: 24px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-45 {
    padding: 45px !important;
}

.p-50 {
    padding: 50px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-12 {
    padding-top: 12px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-16 {
    padding-top: 16px;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-12 {
    padding-right: 12px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-16 {
    padding-right: 16px;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-12 {
    padding-bottom: 12px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-12 {
    padding-left: 12px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-16 {
    padding-left: 16px;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

/***************************************************************************************************
********************************************** Width ***********************************************
***************************************************************************************************/

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-33 {
    width: 33% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-66 {
    width: 66% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100% !important;
}

.mw-100 {
    max-width: 100%;
}

/***************************************************************************************************
********************************************** Height **********************************************
***************************************************************************************************/

.h-100 {
    height: 100% !important;
}

/***************************************************************************************************
***************************************** Scale ****************************************************
***************************************************************************************************/

.sc-200 {
    transform: scale(2);
}

.sc-150 {
    transform: scale(1.5);
}

/***************************************************************************************************
********************************************* Flexbox **********************************************
***************************************************************************************************/

.flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-row-rev {
    flex-direction: row-reverse !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-column-rev {
    flex-direction: column-reverse !important;
}

.flex-jc-c {
    justify-content: center !important;
}

.flex-jc-sa {
    justify-content: space-around !important;
}

.flex-jc-sb {
    justify-content: space-between !important;
}

.flex-jc-se {
    justify-content: space-evenly !important;
}

.flex-jc-fe {
    justify-content: flex-end !important;
}

.flex-jc-fs {
    justify-content: flex-start !important;
}

.flex-ai-bl {
    align-items: baseline;
}

.flex-ai-c {
    align-items: center !important;
}

.flex-ai-fe {
    align-items: flex-end !important;
}

.flex-ai-fs {
    align-items: flex-start !important;
}

.flex-js-fs {
    justify-self: flex-start !important;
}

.flex-js-c {
    justify-self: center !important;
}

.flex-js-fe {
    justify-self: flex-end !important;
}

.flex-as-fs {
    align-self: flex-start !important;
}

.flex-as-c {
    align-self: center !important;
}

.flex-as-fe {
    align-self: flex-end !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

/***************************************************************************************************
********************************************** Fonts ***********************************************
***************************************************************************************************/

.font-bold {
    font-weight: bold !important;
}

/***************************************************************************************************
********************************************* Cursors **********************************************
***************************************************************************************************/

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}

/***************************************************************************************************
***************************************** Miscellaneous *******************************************
***************************************************************************************************/

.border-none {
    border: none !important;
}

.tooltip-line-break {
    white-space: pre-line;
}

.button-tooltip-wrapper {
    display: inline-block;
}

.no-pointer-events {
    pointer-events: none !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.visibility-hidden {
    visibility: hidden;
}

.overflow-hidden-override {
    overflow: hidden !important;
}

/***************************************************************************************************
**************************************** Background Colors *****************************************
***************************************************************************************************/

.bgc-E8F5E9 {
    background-color: #E8F5E9;
}

.bgc-D6F0FF {
    background-color: #D6F0FF;
}

.bgc-FFF3E0 {
    background-color: #FFF3E0;
}

.bgc-FFEBEE {
    background-color: #FFEBEE;
}

.bgc-D5255B-important {
    background-color: #D5255B !important;
}

.bgc-00032 {
    background-color: rgba(0, 0, 0, 0.32);
}

.bgc-006CE2 {
    background-color: #006CE2;
}

.bgc-EF523D {
    background-color: #ef523d;
}

.bgc-FFECEF-important {
    background-color: #ffecef !important;
}

.bgc-DEF7E4-important {
    background-color: #def7e4 !important;
}

.display-none-important{
    display: none !important;
}

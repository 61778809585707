.transactions-table {
	.table-wrapper {
		display: block;
		overflow: auto;
		width: 100%;
	}

	.mat-sort-header-container {
		justify-content: inherit !important;
	}
	.mat-column-description {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 200px;
		padding-right: 12px;
	}
}


.mat-column-status {
	mat-chip.mat-standard-chip {
		padding: 1px 10px !important;
		min-height: 24px;

		&:hover::after,
		&:focus::after {
			opacity: 0 !important;
		}

		&:not(.mat-chip-disabled):active {
			box-shadow: none !important;
		}
	}

	mat-chip.pending,
	mat-chip.posted {
		font-size: 14px;
		&:hover {
			opacity: 1;
		}
	}

	mat-chip.pending {
		color: #a95c00;
		background: #ffeed8;
	}

	mat-chip.posted {
		color: #00813c;
		background: #def7e4;
	}
}

app-transactions-table {
    .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 24px;
    }
}

trovata-paginated-transactions-table {
	.buttons-separator {
		margin-top: -3px;
		margin-right: 5px;
		margin-left: 20px;
		height: 25px;
	}
	.table-header-total {
		display: flex;
	}
}

.paginated-table {
    .col-right,
    .col-right * {
        align-content: flex-end;
        text-align: right;
        justify-content: right;
    }

    .col-center,
    .col-center * {
        align-content: center;
        text-align: center;
        justify-content: center;
    }

    .col-left,
    .col-left * {
        align-content: flex-start;
        text-align: left;
        justify-content: left;
    }

    .col-left *,
    .col-center *,
    .col-right * {
        display: flex;
        flex-direction: row;
    }
}

.hidden-menu, .hidden-menu * {
    display: none !important;
}

.paginated-column-table {
    th.mat-mdc-header-cell.cdk-column-expandedDetail,
    td.mat-mdc-cell.cdk-column-expandedDetail {
        padding-left: 0px !important;
    }

    th.mat-mdc-header-cell.cdk-column-expandedDetail,
    td.mat-mdc-cell.cdk-column-expandedDetail {
        padding-right: 0px !important;
    }

    th .mat-sort-header-content {
        width: 100%;
    }
}

div.icon-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.balance-grouping-tables .paginated-table {
  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type {
      padding-right: 12px ;
  }
}

app-paginated-table {
    .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 24px;
    }
}
